<template>
  <section class="views">
    <headers select="2"></headers>
    <div class="views_content">
      <div class="views_banner">
        <img :src="require('@/assets/image/hodgepodge/h5banner.png')" alt="" />
      </div>

      <!-- 比赛类型Tab -->
      <div class="competition-types">
        <div class="type-tabs">
          <div
            v-for="item in competitionType"
            :key="item.value"
            @click="changeCompetition(item.value)"
            :class="{ active: item.value === currentCompetition }"
            class="tab-item"
          >
            {{ item.title }}
          </div>
        </div>
      </div>

      <template v-if="currentCompetition === '1'">
        <div class="viewsBoxTab">
          <div class="viewsTab" ref="timeSlide">
            <ul>
              <li
                v-for="(item, index) in gradeColumns"
                :key="index"
                @click="tabBut(index)"
                :class="select === index ? 'select' : ''"
                :ref="`tab${index}Ref`"
              >
                <div>{{ item.name }}</div>
                <div>({{ item.fzhu }})</div>
              </li>
            </ul>
          </div>
        </div>

        <div class="viewsList">
          <div class="animate__animated animate__bounceInLeft"
            v-for="(item, index) in gradeColumns[select].pdf"
            :key="+new Date()"
          >
            <div class="item-box" :key="index" @click="pdfBut(item)">
              {{ item.name }}
            </div>
            <p class="tip">{{ item.tip }}</p>
          </div>
        </div>
      </template>

      <div v-else class="viewsList">
        <div class="animate__animated animate__bounceInLeft">
          <div class="item-box" :key="index" @click="openPdf(item)">
            比賽誦材下載
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import headers from "@/components/header/h_header";
const isIOS = /(iPhone|iPad|iPod|iOS)/.test(navigator.userAgent);
export default {
  name: "views",
  data() {
    return {
      gradeColumns: [
        {
          name: "幼稚園初級組",
          fzhu: "K1-K2",
          pdf: [
            {
              name: "比賽誦材下載",
              url:
                "https://materialv3-1256597606.cos.accelerate.myqcloud.com/chineserd/image/d34c9369c843dae7e8b895f48be6088e.pdf",
              tip: "幼稚園初級組在初賽-複賽-決賽階段不更換誦材。",
            },
          ],
        },
        {
          name: "幼稚園高級組",
          fzhu: "K3",
          pdf: [
            {
              name: "比賽誦材下載",
              url:
                "https://materialv3-1256597606.cos.accelerate.myqcloud.com/chineserd/image/f4ddc5baf50bb6da4a31bfeed0480dc5.pdf",
              tip: "幼稚園高級組在初賽-複賽-決賽階段不更換誦材。",
            },
          ],
        },
        {
          name: "小學初級組",
          fzhu: "P1-P2",
          pdf: [
            {
              name: "初賽誦材下載",
              url:
                "https://materialv3-1256597606.cos.accelerate.myqcloud.com/chineserd/image/38ae744ed5d0fb14e0beb5b859d9eb3b.pdf",
            },
            {
              name: "複賽及決賽誦材下載",
              url:
                "https://materialv3-1256597606.cos.accelerate.myqcloud.com/chineserd/image/136cb064de7ca350b73360bd64957584.pdf",
            },
          ],
        },
        {
          name: "小學中級組",
          fzhu: "P3-P4",
          pdf: [
            {
              name: "初賽誦材下載",
              url:
                "https://materialv3-1256597606.cos.accelerate.myqcloud.com/chineserd/image/5193f4e3660e4eeaa45dc4624e913a58.pdf",
            },
            {
              name: "複賽及決賽誦材下載",
              url:
                "https://materialv3-1256597606.cos.accelerate.myqcloud.com/chineserd/image/4c13024a8fa727f68414eec740c4bd8d.pdf",
            },
          ],
        },
        {
          name: "小學高級組",
          fzhu: "P5-P6",
          pdf: [
            {
              name: "初賽誦材下載",
              url:
                "https://materialv3-1256597606.cos.accelerate.myqcloud.com/chineserd/image/a33fe2b091094b76e28e5f11d610e5c4.pdf",
            },
            {
              name: "複賽及決賽誦材下載",
              url:
                "https://materialv3-1256597606.cos.accelerate.myqcloud.com/chineserd/image/6151867112e1e56939b6ecfe045d9bc7.pdf",
            },
          ],
        },
        {
          name: "初中組",
          fzhu: "S1-S3",
          pdf: [
            {
              name: "初賽誦材下載",
              url:
                "https://materialv3-1256597606.cos.accelerate.myqcloud.com/chineserd/image/0c833327cf8bdece38908bc74373a443.pdf",
            },
            {
              name: "複賽及決賽誦材下載",
              url:
                "https://materialv3-1256597606.cos.accelerate.myqcloud.com/chineserd/image/2be1ac961c68a2b1291b0117501bc074.pdf",
            },
          ],
        },
        {
          name: "閃亮組",
          fzhu: "特殊學校",
          pdf: [
            {
              name: "比賽誦材下載",
              url:
                "https://materialv3-1256597606.cos.accelerate.myqcloud.com/chineserd/image/a62555e76ad6ba298f515d1c3c372bee.pdf",
              tip: "閃亮組 在初賽-複賽-決賽階段不更換誦材。",
            },
          ],
        },
        {
          name: "飛躍組",
          fzhu: "非華裔",
          pdf: [
            {
              name: "比賽誦材下載",
              url:
                "https://materialv3-1256597606.cos.accelerate.myqcloud.com/chineserd/image/69449037d6821d3578207b1b5a1ddd64.pdf",
              tip: "飛躍組在初賽-複賽-決賽階段不更換誦材。",
            },
          ],
        },
      ],
      select: 0,
      // 比赛类型
      currentCompetition: "1",
      competitionType: [
        {
          title: "個人賽",
          value: "1",
        },
        {
          title: "團體赛",
          value: "2",
        },
      ],
    };
  },
  computed: {},
  components: {
    headers,
  },
  mounted() {},
  methods: {
    changeCompetition(value) {
      this.currentCompetition = value;
    },
    openPdf() {
      window.open(
        "https://materialv3-1256597606.cos.accelerate.myqcloud.com/chineserd/image/a9f0bce3cecc7699b4388f119042463e.pdf",
        "_blank"
      );
    },
    getPdf(e) {
      this.$axios({
        url: e.url,
        method: "get",
        responseType: "blob",
      })
        .then((response) => {
          this.$toast.clear();
          // this.$toast.success('開始下載');
          // 将文件流转化为 Blob 对象，并创建 a 标签进行下载
          const blob = new Blob([response.data], { type: "application/pdf" });
          const downloadElement = document.createElement("a");
          const href = window.URL.createObjectURL(blob); // 创建下载链接
          downloadElement.href = href;
          downloadElement.download = e.name; // 文件名
          document.body.appendChild(downloadElement);
          downloadElement.click(); // 下载
          document.body.removeChild(downloadElement); // 下载完成后移除元素
          window.URL.revokeObjectURL(href); // 释放占用的内存空间
        })
        .catch((error) => {
          this.$toast.clear();
          console.error(error); // 处理异常情况
        });
    },
    pdfBut(e) {
      // this.$dialog
      //   .alert({
      //     title: '提示',
      //     message: '誦材暫未開放下載',
      //     confirmButtonText: '確認'
      //   })
      //   .then(() => {
      //     // on close
      //   })
      // return
      if (e.url) {
        let a = document.createElement("a");
        a.href = e.url;
        a.click();
        // if(isIOS){
        // this.getPdf(e);
        // let a = document.createElement('a')
        // a.href = e.url
        // a.click()
        // }else{
        //   let url = 'https://chineserd.cn/h5/html/regulations/index.html?url=' + e.url
        //   let a = document.createElement('a')
        //   a.href = url
        //   a.click()
        // }
      } else {
        this.$dialog
          .alert({
            title: "提示",
            message: "決賽誦材暫未開放下載",
            confirmButtonText: "確認",
          })
          .then(() => {
            // on close
          });
      }
    },
    tabBut(e) {
      this.select = e;
      // 触发滑动方法
      this.scrollLeftTo(e);
    },
    // 滑动
    scrollLeftTo(name) {
      const ref = `tab${name}Ref`;
      const nav = this.$refs.timeSlide;
      console.log("额:", this.$refs.timeSlide.offsetWidth);
      const title = this.$refs[ref][0];
      // 计算位移偏差
      const to = title.offsetLeft - (nav.offsetWidth - title.offsetWidth) / 2;
      nav.scrollLeft = to;
    },
  },
};
</script>

<style lang="scss" scoped>
.competition-types {
  position: sticky;
  top: 0;
  width: 100%;
  margin-bottom: 30px;
  background: #d34a00;
  z-index: 99;
  .type-tabs {
    display: flex;
    justify-content: center;
    gap: 10px;
    .tab-item {
      min-width: 2rem;
      padding: 0.35rem 0.12rem;
      color: #fff;
      cursor: pointer;
      font-size: 0.32rem;

      &.active {
        font-weight: 600;
        color: #903300;
        background-image: url("~@/assets/image/hodgepodge/tabbuttom.png");
        background-size: 100% 100%;
        transform: scale(1.2);
      }
    }
  }
}

.views {
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: linear-gradient(225deg, #ff8310 0%, #fe840f 100%);
  .views_content::-webkit-scrollbar {
    display: none;
  }
  .views_content {
    width: 100%;
    height: calc(100% - 0.92rem);
    overflow: scroll;

    .views_banner {
      width: 100%;
      display: grid;

      img {
        width: 100%;
      }
    }

    .viewsTab::-webkit-scrollbar {
      display: none;
    }

    .viewsBoxTab {
      width: 100%;
    }

    .viewsTab {
      width: 100%;
      padding: 0.16rem 0;
      box-sizing: border-box;
      overflow-y: scroll;
      margin-top: -6%;

      ul {
        width: 100%;
        height: 1.28rem;
        display: inline-flex;
        justify-content: flex-start;
        align-items: center;

        li {
          width: 3.14rem;
          height: 100%;
          display: flex;
          align-content: center;
          align-items: center;
          flex-wrap: wrap;
          font-family: PingFang SC-Semibold, PingFang SC;
          line-height: 0.38rem;
          flex: 0 0 auto;
          background: #d34a00;

          div {
            width: 100%;
          }

          div:nth-child(1) {
            font-size: 0.28rem;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 400;
            color: #ffffff;
            line-height: 0.33rem;
          }

          div:nth-child(2) {
            font-size: 0.22rem;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 400;
            color: #ffffff;
            line-height: 0.26rem;
          }
        }

        .select {
          font-size: 0.32rem;
          font-weight: 600;
          color: #903300;
          // padding: 0.16rem 0;
          background: url("~@/assets/image/hodgepodge/tabbuttom.png") no-repeat;
          background-size: 100% 100%;

          div:nth-child(1) {
            font-size: 0.32rem !important;
            font-family: PingFang SC-Semibold, PingFang SC;
            font-weight: 600 !important;
            color: #903300;
            line-height: 0.38rem !important;
          }

          div:nth-child(2) {
            font-size: 0.26rem !important;
            font-family: PingFang SC-Semibold, PingFang SC;
            font-weight: 600 !important;
            color: #903300;
            line-height: 0.3rem !important;
          }
        }
      }
    }

    .viewsList {
      width: 100%;
      margin-top: 0.32rem;

      padding: 0.32rem;
      box-sizing: border-box;
      .item-box {
        width: 100%;
        height: 2.48rem;
        background: #fff8de;
        box-shadow: 0px 0px 0.08rem 0px rgba(126, 56, 0, 0.26);
        border: 0.08rem solid #fff0e1;
        border-radius: 0.32rem;
        margin-bottom: 0.32rem;

        font-size: 0.48rem;
        font-family: PingFang SC-Semibold, PingFang SC;
        font-weight: 600;
        color: #ff782f;
        line-height: 0.56rem;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .tip {
        color: #fff;
        font-size: 12px;
      }
    }
  }
}
</style>
