<template>
  <section class="views">
    <!-- header -->
    <headers select="2"></headers>
    <!-- banner  -->
    <div class="posters">
      <van-image
        width="100%"
        :src="require('@/assets/image/hodgepodge/pcbanner.png')"
      />
      <div class="posters_ewm" v-if="false">
        <div class="ewm">
          <div class="ewm2">
            <div class="ewm3">
              <img :src="require('@/assets/image/csewm.png')" alt="" />
            </div>
            <div class="ewm3_value">掃碼報名比賽</div>
          </div>
        </div>
      </div>
    </div>

    <!-- 比赛类型Tab -->
    <div class="competition-types">
      <div class="type-tabs">
        <div
          v-for="item in competitionType"
          :key="item.value"
          @click="changeCompetition(item.value)"
          :class="{ active: item.value === currentCompetition }"
          class="tab-item"
        >
          {{ item.title }}
        </div>
      </div>
    </div>

    <div v-if="currentCompetition === '1'" class="content">
      <div class="viewsBoxTab">
        <div class="viewsTab">
          <ul>
            <li
              v-for="(item, index) in gradeColumns"
              :key="index"
              @click="tabBut(index)"
              :class="select === index ? 'select' : ''"
            >
              <div>{{ item.name }}</div>
              <div>({{ item.fzhu }})</div>
            </li>
          </ul>
        </div>
      </div>

      <div class="viewsList">
        <div v-for="item in gradeColumns[select].pdf"
          :key="+new Date()"
          class="animate__animated animate__bounceInLeft"
        >
          <div class="item-box" @click="pdfBut(item)">{{ item.name }}</div>
          <p class="tip">{{ item.tip }}</p>
        </div>
      </div>
    </div>

    <div v-else class="content">
      <div class="viewsList">
        <div class="animate__animated animate__bounceInLeft">
          <div class="item-box" @click="openPdf">比賽誦材下載</div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import headers from "@/components/header/pc_header";
export default {
  name: "signup",
  data() {
    return {
      gradeColumns: [
        {
          name: "幼稚園初級組",
          fzhu: "K1-K2",
          pdf: [
            {
              name: "比賽誦材下載",
              url:
                "https://materialv3-1256597606.cos.accelerate.myqcloud.com/chineserd/image/d34c9369c843dae7e8b895f48be6088e.pdf",
              tip: '幼稚園初級組在初賽-複賽-決賽階段不更換誦材。',
            },
          ],
        },
        {
          name: "幼稚園高級組",
          fzhu: "K3",
          pdf: [
            {
              name: "比賽誦材下載",
              url:
                "https://materialv3-1256597606.cos.accelerate.myqcloud.com/chineserd/image/f4ddc5baf50bb6da4a31bfeed0480dc5.pdf",
              tip: '幼稚園高級組在初賽-複賽-決賽階段不更換誦材。',
            },
          ],
        },
        {
          name: "小學初級組",
          fzhu: "P1-P2",
          pdf: [
            {
              name: "初賽誦材下載",
              url:
                "https://materialv3-1256597606.cos.accelerate.myqcloud.com/chineserd/image/38ae744ed5d0fb14e0beb5b859d9eb3b.pdf",
            },
            {
              name: "複賽及決賽誦材下載",
              url:
                "https://materialv3-1256597606.cos.accelerate.myqcloud.com/chineserd/image/136cb064de7ca350b73360bd64957584.pdf",
            },
          ],
        },
        {
          name: "小學中級組",
          fzhu: "P3-P4",
          pdf: [
            {
              name: "初賽誦材下載",
              url:
                "https://materialv3-1256597606.cos.accelerate.myqcloud.com/chineserd/image/5193f4e3660e4eeaa45dc4624e913a58.pdf",
            },
            {
              name: "複賽及決賽誦材下載",
              url:
                "https://materialv3-1256597606.cos.accelerate.myqcloud.com/chineserd/image/4c13024a8fa727f68414eec740c4bd8d.pdf",
            },
          ],
        },
        {
          name: "小學高級組",
          fzhu: "P5-P6",
          pdf: [
            {
              name: "初賽誦材下載",
              url: "https://materialv3-1256597606.cos.accelerate.myqcloud.com/chineserd/image/a33fe2b091094b76e28e5f11d610e5c4.pdf",
            },
            {
              name: "複賽及決賽誦材下載",
              url: "https://materialv3-1256597606.cos.accelerate.myqcloud.com/chineserd/image/6151867112e1e56939b6ecfe045d9bc7.pdf",
            },
          ],
        },
        {
          name: "初中組",
          fzhu: "S1-S3",
          pdf: [
            {
              name: "初賽誦材下載",
              url: "https://materialv3-1256597606.cos.accelerate.myqcloud.com/chineserd/image/0c833327cf8bdece38908bc74373a443.pdf",
            },
            {
              name: "複賽及決賽誦材下載",
              url: "https://materialv3-1256597606.cos.accelerate.myqcloud.com/chineserd/image/2be1ac961c68a2b1291b0117501bc074.pdf",
            },
          ],
        },
        {
          name: "閃亮組",
          fzhu: "特殊學校",
          pdf: [
            {
              name: "比賽誦材下載",
              url:
                "https://materialv3-1256597606.cos.accelerate.myqcloud.com/chineserd/image/a62555e76ad6ba298f515d1c3c372bee.pdf",
              tip: '閃亮組 在初賽-複賽-決賽階段不更換誦材。',
            },
          ],
        },
        {
          name: "飛躍組",
          fzhu: "非華裔",
          pdf: [
            {
              name: "比賽誦材下載",
              url:
                "https://materialv3-1256597606.cos.accelerate.myqcloud.com/chineserd/image/69449037d6821d3578207b1b5a1ddd64.pdf",
              tip: '飛躍組在初賽-複賽-決賽階段不更換誦材。',
            },
          ],
        },
      ],
      select: 0,
      // 比赛类型
      currentCompetition: '1',
      competitionType:[
        {
          title:'個人賽',
          value:'1'
        },
        {
          title:'團體赛',
          value:'2'
        },
      ],
    };
  },
  computed: {},
  components: {
    headers,
  },
  methods: {
    changeCompetition(value) {
      this.currentCompetition = value
    },
    getPdf(e) {
      this.$axios({
        url: e.url,
        method: "get",
        responseType: "blob",
      })
        .then((response) => {
          this.$toast.clear();
          this.$toast.success("開始下載");
          // 将文件流转化为 Blob 对象，并创建 a 标签进行下载
          const blob = new Blob([response.data], { type: "application/pdf" });
          const downloadElement = document.createElement("a");
          const href = window.URL.createObjectURL(blob); // 创建下载链接
          downloadElement.href = href;
          downloadElement.download = e.name; // 文件名
          document.body.appendChild(downloadElement);
          downloadElement.click(); // 下载
          document.body.removeChild(downloadElement); // 下载完成后移除元素
          window.URL.revokeObjectURL(href); // 释放占用的内存空间
        })
        .catch((error) => {
          this.$toast.clear();
          console.error(error); // 处理异常情况
        });
    },
    pdfBut(e) {
      // this.$dialog
      //   .alert({
      //     title: '提示',
      //     message: '誦材暫未開放下載',
      //     confirmButtonText: '確認'
      //   })
      //   .then(() => {
      //     // on close
      //   })

      if (e.url) {
        // this.getPdf(e);
        window.open(e.url, "_blank");
      } else {
        this.$dialog
          .alert({
            title: "提示",
            message: "決賽誦材暫未開放下載",
            confirmButtonText: "確認",
          })
          .then(() => {
            // on close
          });
      }
    },
    openPdf() {
      window.open('https://materialv3-1256597606.cos.accelerate.myqcloud.com/chineserd/image/a9f0bce3cecc7699b4388f119042463e.pdf', "_blank");
    },
    tabBut(e) {
      this.select = e;
    },
  },
};
</script>

<style lang="scss" scoped>
.competition-types {
  position: sticky;
  top: 80px;
  width: 100%;
  margin-bottom: 30px;
  background: #d34a00;
  z-index: 99999;
  .type-tabs {
    display: flex;
    justify-content: center;
    gap: 10px;
    .tab-item {
      min-width: 120px;
      padding: 20px 10px;
      color: #fff;
      cursor: pointer;
      font-size: 16px;

      &.active {
        font-weight: 600;
        color: #903300;
        background-image: url("~@/assets/image/hodgepodge/tabbuttom.png");
        background-size: 100% 100%;
        transform: scale(1.2)
      }
    }
  }
}

.views {
  width: 100%;
  height: 100vh;
  overflow-x: hidden;
  background: #ff782f;

  .posters {
    width: 100%;
    display: flex;
    position: relative;

    img {
      width: 100%;
    }

    .posters_ewm {
      position: absolute;
      width: 1024px;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      margin: auto;
      display: flex;
      align-items: center;
      justify-content: right;

      .ewm {
        width: 161px;
        height: 189px;
        background: rgba(255, 255, 255, 0.63);
        border-radius: 16px 16px 16px 16px;
        padding: 8px;
        box-sizing: border-box;

        .ewm2 {
          width: 100%;
          height: 100%;
          padding: 6px;
          background: #fdcf1d;
          border-radius: 12px 12px 12px 12px;
          box-sizing: border-box;

          .ewm3 {
            width: 132px;
            height: 132px;
            background: #ffffff;
            border-radius: 8px 8px 8px 8px;
            margin-bottom: 6px;
            overflow: hidden;

            img {
              width: 100%;
              height: 100%;
              object-fit: contain;
            }
          }

          .ewm3_value {
            font-size: 16px;
            font-family: PingFang SC-Semibold, PingFang SC;
            font-weight: 600;
            color: #323232;
            line-height: 19px;
            text-align: center;
          }
        }
      }
    }
  }

  .content {
    width: 100%;
    margin: auto;

    .viewsBoxTab {
      width: 100%;
      position: relative;
      background: #d34a00;

      .viewsTab {
        width: 100%;
        max-width: 1024px;
        margin: auto;
        box-sizing: border-box;
        display: flex;

        ul {
          width: 100%;
          height: 64px;
          display: inline-flex;
          justify-content: center;
          align-items: center;

          li {
            width: 152px;
            height: 100%;
            display: flex;
            align-content: center;
            align-items: center;
            flex-wrap: wrap;
            font-family: PingFang SC-Semibold, PingFang SC;
            line-height: 20px;
            flex: 0 0 auto;
            background: #d34a00;
            cursor: pointer;

            div {
              width: 100%;
            }

            div:nth-child(1) {
              font-size: 14px;
              font-family: PingFang SC-Regular, PingFang SC;
              font-weight: 400;
              color: #ffffff;
            }

            div:nth-child(2) {
              font-size: 11px;
              font-family: PingFang SC-Regular, PingFang SC;
              font-weight: 400;
              color: #ffffff;
            }
          }

          .select {
            font-size: 16px;
            font-weight: 600;
            color: #903300;
            // padding: 8px 0;
            background: url("~@/assets/image/hodgepodge/tabbuttom.png")
              no-repeat;
            background-size: 100% 100%;

            div:nth-child(1) {
              font-size: 16px !important;
              font-family: PingFang SC-Semibold, PingFang SC;
              font-weight: 600 !important;
              color: #903300;
            }

            div:nth-child(2) {
              font-size: 13px !important;
              font-family: PingFang SC-Semibold, PingFang SC;
              font-weight: 600 !important;
              color: #903300;
            }
          }
        }
      }
    }

    .viewsList {
      width: 100%;
      max-width: 1024px;
      margin: 64px auto 50px auto;
      padding: 0.32rem;
      box-sizing: border-box;

      .item-box {
        width: 640px;
        height: 124px;
        margin: auto;
        background: #fff8de;
        box-shadow: 0px 0px 4px 0px rgba(126, 56, 0, 0.26);
        border: 4px solid #fff0e1;
        border-radius: 16px;
        margin-bottom: 20px;
        font-size: 24px;
        font-family: PingFang SC-Semibold, PingFang SC;
        font-weight: 600;
        color: #ff782f;
        line-height: 28px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
      }
      .tip {
        color: #fff;
        font-size: 14px;
      }
    }
  }
}
</style>
